@import url('https://cdn.syncfusion.com/ej2/material.css');


.sidebar {
  box-shadow: rgb(113 122 131 / 11%) 0px 7px 30px 0px;
}

.nav-item,
.navbar {
  z-index: 10000;
}

@media screen and (max-width:800px) {
  .sidebar {
    z-index: 10000000;
  }
}

.e-dlg-center-center,
.e-quick-popup-wrapper.e-device {
  z-index: 1000000 !important;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(216, 216, 216);
  border-radius: 40px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

/* color-picker style  */

#preview {
  background: transparent url('https://ej2.syncfusion.com/react/demos/src/color-picker/images/pen.png') no-repeat;
  display: inline-block;
  height: 80px;
  margin: 10px 0;
  min-width: 300px;
  max-width: 600px;
  background-color: #008000;
}

/* home */

.navbar {
  background-color: #333;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.logo a {
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
}

.nav-links {
  list-style-type: none;
  display: flex;
}

.nav-links li {
  margin-right: 20px;
}

.nav-links li a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
}

.nav-links li a:hover {
  text-decoration: underline;
}



/* home */

/* Animation */
.landing-page .content {
  animation: fadeInUp 1s ease;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


.footer {
  background-color: #1c8c58;
  color: white;
  padding: 20px;
  text-align: center;
  max-height: 25%;
}

.con {
  font-size: 1.3rem;
}

.social-links {
  margin-top: 9px;
  display: flex;
  justify-content: center;
}

.social-links img {
  width: 50px;
  height: 50px;
  margin: 0 20px;
}

.made-by {
  margin-top: 5px;
  font-size: 0.8rem;
}


/* navbar  */
.landing-page {
  position: relative;
  height: 10vh;
  overflow: hidden;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.content {
  position: relative;
  z-index: 1;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
}

.welcome {
  font-size: 1.9rem;

}



.e-input-group:not(.e-float-icon-left),
.e-input-group.e-success:not(.e-float-icon-left),
.e-input-group.e-warning:not(.e-float-icon-left),
.e-input-group.e-error:not(.e-float-icon-left),
.e-input-group.e-control-wrapper:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
  border: none;
}